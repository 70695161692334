import React from "react";
import { Link, graphql } from "gatsby";
import { Container, Row, Col, Accordion } from 'react-bootstrap'
import Layout from "../../components/Layout";
import ContextAwareToggle from "../../components/ContextAwareToggle";
import SEO from "../../components/SEO";

class Support extends React.Component {

    // componentDidMount() {
    //     const { location } = this.props;

    //     var host_website = location.host ? location.host : "";
    //     var new_online_doc_url;

    //     if (host_website === "localhost:8000" || host_website === "127.0.0.1:8000") {
    //         new_online_doc_url = "http://protoweb.appvity.com:8083/";
    //     } else if (host_website === "protoweb.appvity.com:8082") {
    //         new_online_doc_url = "http://protoweb.appvity.com:8083/";
    //     } else if (host_website === "vmweb:8082") {
    //         new_online_doc_url = "http://vmweb:8083/";
    //     } else if (host_website === "10.8.30.46:8082") {
    //         new_online_doc_url = "http://10.8.30.46:8083/";
    //     } else if (host_website === "10.9.9.104:8082") {
    //         new_online_doc_url = "http://10.9.9.104:8083/";
    //     } else {
    //         new_online_doc_url = "https://www.appvity.com:8083/";
    //     }

    //     document.querySelector(".app-access-comprehensive-information-about-our-products .app-access-comprehensive-information-products-detail .app-access-comprehensive-information-products-detail-col-custom .app-access-comprehensive-information-products-content a.app-access-comprehensive-information-products-content-etask").setAttribute("href", new_online_doc_url + "odoc/eTask-User-Guide/Introduction/01-00-introduction.html");
    //     document.querySelector(".app-access-comprehensive-information-about-our-products .app-access-comprehensive-information-products-detail .app-access-comprehensive-information-products-detail-col-custom .app-access-comprehensive-information-products-content a.app-access-comprehensive-information-products-content-ehelpdesk").setAttribute("href", new_online_doc_url + "odoc/07-00-00.html");
    //     document.querySelector(".app-access-comprehensive-information-about-our-products .app-access-comprehensive-information-products-detail .app-access-comprehensive-information-products-detail-col-custom .app-access-comprehensive-information-products-content a.app-access-comprehensive-information-products-content-rest-api").setAttribute("href", new_online_doc_url + "odoc/eTask_Rest_API_Document/08-03-01.html");
    //     document.querySelector(".app-access-comprehensive-information-about-our-products .app-access-comprehensive-information-products-detail .app-access-comprehensive-information-products-detail-col-custom .app-access-comprehensive-information-products-content a.app-access-comprehensive-information-products-content-powershell").setAttribute("href", new_online_doc_url + "odoc/09-00-00.html");
    //     document.querySelector(".app-access-comprehensive-information-about-our-products .app-access-comprehensive-information-products-detail .app-access-comprehensive-information-products-detail-col-custom .app-access-comprehensive-information-products-content a.app-access-comprehensive-information-products-content-app-notes").setAttribute("href", new_online_doc_url + "odoc/10-00-00.html");
    //     document.querySelector(".app-access-comprehensive-information-about-our-products .app-access-comprehensive-information-products-detail a.app-access-comprehensive-information-products-a-tag-custom").setAttribute("href", new_online_doc_url);
    // }

    render() {
        const { data } = this.props;
        const introduction = data.introduction.nodes;
        const introduction_vi = data.introduction_vi.nodes;
        // const submit_request_solve_problem_expert = data.submit_request_solve_problem_expert.nodes;
        // const submit_request_solve_problem_expert_vi = data.submit_request_solve_problem_expert_vi.nodes;
        const suggested_frequently_asked_questions = data.suggested_frequently_asked_questions.nodes;
        const suggested_frequently_asked_questions_vi = data.suggested_frequently_asked_questions_vi.nodes;
        const have_quick_questions = data.have_quick_questions.nodes;
        const have_quick_questions_vi = data.have_quick_questions_vi.nodes;

        return (
            <Layout>
                <SEO title="Support Center" description="Appvity support provides user guide, and contact us for more detail." meta={[{ name: `keywords`, content: ["Appvity contact us", "eProducts user guide", "eTask user guide", "eHelpdesk user guide"] }]} pathname="/support" />
                <div className="main-content-padding">
                    <div className="app-general-seciton app-support-landing-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="linear">
                        <Container>
                            <div className="app-support-landing-introduction-detail">
                                <Row className="app-support-landing-introduction-row-custom">
                                    <Col className="app-support-landing-introduction-col-custom" xs={12} md={12} lg={5}>
                                        {introduction.map((res) => (
                                            <div className="app-data-en app-support-landing-introduction-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                        {introduction_vi.map((res) => (
                                            <div className="app-data-vi app-support-landing-introduction-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                    </Col>
                                    <Col xs={12} md={12} lg={1}></Col>
                                    <Col className="app-support-landing-introduction-col-custom" xs={12} md={12} lg={6}>
                                        <div className="app-support-landing-introduction-right">
                                            <img className="app-support-landing-introduction-img" src="/app-support-landing-introduction.png" alt="support landing introduction" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    {/* <div className="app-general-seciton app-submit-requests-solve-problems-directly" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="linear">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    {submit_request_solve_problem_expert.map((res) => (
                                        <div className="app-data-en app-submit-requests-solve-problems-directly-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                    {submit_request_solve_problem_expert_vi.map((res) => (
                                        <div className="app-data-vi app-submit-requests-solve-problems-directly-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                        </Container>
                    </div> */}
                    <div className="app-general-seciton app-access-comprehensive-information-about-our-products" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="linear">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    <div className="app-title">
                                        <h2 className="app-data-en">Access comprehensive information about our products</h2>
                                        <h2 className="app-data-vi">Access comprehensive information about our products</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                            <div className="app-access-comprehensive-information-products-detail">
                                <Row>
                                    <Col xs={12} md={12} lg={1}></Col>
                                    <Col xs={12} md={12} lg={10}>
                                        <Row>
                                            <Col className="app-access-comprehensive-information-products-detail-col-custom" xs={12} md={12} lg={4}>
                                                <Row>
                                                    <Col>
                                                        <div className="app-access-comprehensive-information-products-content">
                                                            <img src="/app-access-comprehensive-information-etask-user-guilde.svg" alt="app access comprehensive information eTask user guilde" />
                                                            <a className="app-access-comprehensive-information-products-content-etask" href="/OnlineDoc/odoc/eTask-User-Guide/etask-introduction.html">eTask<br/><h6 className="app-access-comprehensive-information-products-content-link-child">User Guide</h6></a>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="app-access-comprehensive-information-products-content">
                                                            <img src="/app-access-comprehensive-information-ehelpdesk-user-guilde.svg" alt="app access comprehensive information eHelpdesk user guilde" />
                                                            <a className="app-access-comprehensive-information-products-content-ehelpdesk" href="/OnlineDoc/odoc/eHelpdesk-User-Guide/introduction-to-eHelpdesk.html">eHelpdesk<br/><h6 className="app-access-comprehensive-information-products-content-link-child">User Guide</h6></a>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="app-access-comprehensive-information-products-detail-col-custom" xs={12} md={12} lg={4}>
                                                <Row>
                                                    <Col>
                                                        <div className="app-access-comprehensive-information-products-content">
                                                            <img src="/app-access-comprehensive-information-rest-api.svg" alt="app access comprehensive information rest api" />
                                                            <a className="app-access-comprehensive-information-products-content-rest-api" href="/OnlineDoc/odoc/eTask_Rest_API_Document/08-03-01.html">REST API</a>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="app-access-comprehensive-information-products-content">
                                                            <img src="/app-access-comprehensive-information-powershell.svg" alt="app access comprehensive information powershell" />
                                                            <a className="app-access-comprehensive-information-products-content-powershell" href="/OnlineDoc/odoc/eTask_PowerShell_Module/introduction.html">PowerShell</a>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="app-access-comprehensive-information-products-detail-col-custom" xs={12} md={12} lg={4}>
                                                <Row>
                                                    <Col>
                                                        <div className="app-access-comprehensive-information-products-content">
                                                            <img src="/app-access-comprehensive-information-app-notes.svg" alt="app access comprehensive information app notes" />
                                                            <a className="app-access-comprehensive-information-products-content-app-notes" href="/OnlineDoc/odoc/Application_notes/introduction.html">App Notes</a>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="app-access-comprehensive-information-products-content">
                                                            <img src="/app-access-comprehensive-information-blogs.svg" alt="app access comprehensive information blogs" />
                                                            <a href="/support/blogs">Blogs</a>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <a className="app-access-comprehensive-information-products-a-tag-custom" href="/OnlineDoc">Learn more <img src="/app-support-landing-icon.svg" alt="app support landing icon" /></a>
                                    </Col>
                                    <Col xs={12} md={12} lg={1}></Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-suggested-frequently-asked-questions" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="linear">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    <div className="app-title">
                                        <h2 className="app-data-en">Suggested Frequently Asked Questions</h2>
                                        <h2 className="app-data-vi">Suggested Frequently Asked Questions</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                            <div className="app-suggested-frequently-asked-questions-detail">
                                <Row>
                                    <Col xs={12} md={12} lg={2}></Col>
                                    <Col xs={12} md={12} lg={8}>
                                        {suggested_frequently_asked_questions.map((res) => (
                                            // defaultActiveKey={1} => because the MD file that has keyNumber = 1 always show first when load page
                                            <Accordion className="app-data-en app-suggested-frequently-asked-questions-detail-content" key={res.id} defaultActiveKey={1}>
                                                <div className="app-suggested-frequently-asked-questions-detail-content-header">
                                                    <ContextAwareToggle
                                                        eventKey={res.frontmatter.keyNumber}
                                                    >
                                                        <h6>{res.frontmatter.title}</h6>
                                                    </ContextAwareToggle>
                                                </div>
                                                <Accordion.Collapse eventKey={res.frontmatter.keyNumber}>
                                                    <div className="app-suggested-frequently-asked-questions-detail-content-body"
                                                        dangerouslySetInnerHTML={{
                                                            __html: res.html
                                                        }}
                                                    />
                                                </Accordion.Collapse>
                                            </Accordion>
                                        ))}
                                        {suggested_frequently_asked_questions_vi.map((res) => (
                                            // defaultActiveKey={1} => because the MD file that has keyNumber = 1 always show first when load page
                                            <Accordion className="app-data-vi app-suggested-frequently-asked-questions-detail-content" key={res.id} defaultActiveKey={1}>
                                                <div className="app-suggested-frequently-asked-questions-detail-content-header">
                                                    <ContextAwareToggle
                                                        eventKey={res.frontmatter.keyNumber}
                                                    >
                                                        <h6>{res.frontmatter.title}</h6>
                                                    </ContextAwareToggle>
                                                </div>
                                                <Accordion.Collapse eventKey={res.frontmatter.keyNumber}>
                                                    <div className="app-suggested-frequently-asked-questions-detail-content-body"
                                                        dangerouslySetInnerHTML={{
                                                            __html: res.html
                                                        }}
                                                    />
                                                </Accordion.Collapse>
                                            </Accordion>
                                        ))}
                                        <Link to="/support/faqs">Learn more <img src="/app-support-landing-icon.svg" alt="app support landing icon"/></Link>
                                    </Col>
                                    <Col xs={12} md={12} lg={2}></Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    {/* <div className="app-general-seciton app-have-a-quick-question" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="linear">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    {have_quick_questions.map((res) => (
                                        <div className="app-data-en app-have-a-quick-question-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                    {have_quick_questions_vi.map((res) => (
                                        <div className="app-data-vi app-have-a-quick-question-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                        </Container>
                    </div> */}
                </div>
            </Layout>
        )
    }
}

export default Support;

export const query = graphql`
    query SupportCenterPage {
        introduction: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "support_center_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
        introduction_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "support_center_introduction_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        submit_request_solve_problem_expert: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "support_center_submit_request_solve_problem_expert" } } }
        ) {
            nodes {
                html
                id
            }
        }
        submit_request_solve_problem_expert_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "support_center_submit_request_solve_problem_expert_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        suggested_frequently_asked_questions: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "support_center_suggested_frequently_asked_questions" } } }
            sort: {fields: frontmatter___createAt, order: ASC}
        ) {
            nodes {
                html
                frontmatter {
                    title
                    keyNumber
                }
                id
            }
        }
        suggested_frequently_asked_questions_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "support_center_suggested_frequently_asked_questions_vi" } } }
            sort: {fields: frontmatter___createAt, order: ASC}
        ) {
            nodes {
                html
                frontmatter {
                    title
                    keyNumber
                }
                id
            }
        }
        have_quick_questions: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "support_center_have_quick_questions" } } }
        ) {
            nodes {
                html
                id
            }
        }
        have_quick_questions_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "support_center_have_quick_questions_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
    }
`;